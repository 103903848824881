<template>
    <Head :title="'Client '+ getFullName(resource.data) + ' - PrivyTrips'"></Head>
    <AppMainLayout
        :title="getFullName(resource.data)"
        :breadcrumbs="[{title: 'Clients', link: getRoute('clients')}, getFullName(resource.data)]">
        <template #header-right>
            <acms-v-btn v-if="canShowHeaderAddButton.passengers  && backendPermissions?.allowCreatePassengers"
                        icon="plus" title="Add passenger"
                        :class="getHeaderBtnClass"
                        icon-size="12"
                        @click="tabPassengers.formCreateShow = true"/>
            <acms-v-btn v-if="canShowHeaderAddButton.request"
                        icon="plus" title="Add request"  icon-size="12"
                        :class="getHeaderBtnClass"
                        @click="tabRequests.formCreateShow = true"/>
            <acms-v-btn icon="plus" title="Add contact"
                        :class="getHeaderBtnClass"
                        icon-size="12"
                        v-if="canShowHeaderAddButton.linkedContacts"
                        @click="tabLinkedContacts.formCreateShow = true"/>
        </template>
        <template #content>
            <div class="row g-4">
                <div class="client-show-overview page-inner-sidebar col-3">
                    <BoxCardClientOverview
                        v-bind="getPropsForBoxClientOverview"
                    />
                </div>
                <div class="client-show-full-info page-inner-content col-9">
                    <template v-if="tabRequests.formCreateShow">
                        <BoxClientRequestsAndLinkedContacts
                            linkedContactsEmpty
                            :mainClientId="getClientId"
                            @cancel="tabRequests.formCreateShow = false"
                        />
                    </template>
                    <acms-v-tabs v-else
                                 v-bind="CLIENT_TABS_SETTINGS"
                                 :items="getTabsItems"
                                 :active="locState.activeTab"
                                 @beforeChangeTab="onChangeTabHandle"
                    >
                        <template #tab-client-info>
                            <EntityClientGeneralInfo
                                v-model:generalInfo="clientInfoForm.generalInfo"
                                v-model:contacts="clientInfoForm.contacts"
                                v-model:locationTimezone="clientInfoForm.locationTimezone"
                                v-model:ffPrograms="clientInfoForm.ff_programs"
                                v-model:ff-programs-delete-ids="clientInfoForm.ff_programs_delete_ids"
                                :errors="clientInfoForm.errors"
                                :parentProps="$props"
                            />
                            <BoxPassportData
                                v-model="clientPassportForm"
                                :errors="clientPassportForm.errors"
                                disableRequiredFields
                                class="border-bottom-gray"
                                noAlert
                            />
                            <div class="client-info-block  ">
                                <h4>Notes about client</h4>
                                <div class="client-info-block-content row">
                                    <acms-v-form-field
                                        label="Notes"
                                        name="notes"
                                        class="col-12"
                                        :modeTextarea="{
                                            resize:true,
                                            minHeight:96
                                        }"
                                        v-model="clientInfoForm.notes"
                                    />
                                </div>
                            </div>
                        </template>
                        <template #tab-requests>
                            <BoxTableRequests
                                tableClass="p-4"
                                :collection="requests"
                                @addButtonClick="tabRequests.formCreateShow = true"
                                v-model:page="requestTableForm.page"
                                v-model:entries="requestTableForm.limit"
                                v-model:search="requestTableForm.search"
                                @sortChange="requestTableSortHandle"
                                :agents="agents_to_select"
                                @update:selectedRows="updateSelectedRows"
                                @handle:assignAgent="handleAssignAgent"
                                :hideAssignAgentBtn="!backendPermissions.allowAssignAgents"
                            />
                        </template>
                        <template #tab-passengers>
                            <template v-if="tabPassengers.formCreateShow">
                                <BoxListPassportData
                                    :mainClientId="getClientId"
                                    @cancel="passengersHandlers.cancel"
                                />
                            </template>
                            <template v-else-if="tabPassengers.formEditShow">
                                <BoxPassportDataEdit
                                    :mainClientId="getClientId"
                                    v-model="tabPassengers.formEditData"
                                    @cancel="passengersHandlers.cancel"
                                    showDeleteButton
                                />
                            </template>
                            <template v-else>
                                <PartialPassengersTable
                                    :passengers="passengers"
                                    :client-id="getClientId"
                                    @edit="passengersHandlers.edit"
                                    @add="tabPassengers.formCreateShow = true"
                                />
                            </template>
                        </template>
                        <template #tab-linked-contacts>
                            <template v-if="tabLinkedContacts.formEditShow || tabLinkedContacts.formCreateShow">
                                <FeatureLinkedContactsCreateAndEdit
                                    edit-class="p-4"
                                    create-class="p-4"
                                    response-only-key="linkedContacts"
                                    :mainClientId="getClientId"
                                    :state="tabLinkedContacts"
                                    @cancel="tabLinkedContactsHandlers.cancel"
                                />
                            </template>
                            <template v-if="!tabLinkedContacts.formCreateShow && !tabLinkedContacts.formEditShow">
                                <PartialLinkedContactsTable
                                    :linked-contacts="linkedContacts"
                                    :clientId="getClientId"
                                    @edit="tabLinkedContactsHandlers.edit"
                                    @delete="tabLinkedContactsHandlers.wantsToDelete"
                                    @add="tabLinkedContactsHandlers.create"
                                />
                            </template>
                        </template>
                    </acms-v-tabs>
                </div>
            </div>
            <AlertCancel
                v-model:show="tabPassengers.modalAlert"
                type="passenger"
                @cancel="passengersHandlers.cancel()"
            />
            <AlertCancel
                v-model:show="tabLinkedContacts.modalAlert"
                type="linkedContact"
                @cancel="tabLinkedContactsHandlers.cancel()"
            />
            <AlertDelete
                v-model:show="tabLinkedContacts.modalDelete"
                type="linkedContact"
                @delete="tabLinkedContactsHandlers.delete"
            />
            <acms-v-bottom-actions
                v-if="canShowBottomButtonsForClient"
                hide-cancel
                @save="clientSaveHandle"
                @saveAndExit="clientSaveHandle(true)"
                :disabled-save="locState.disableSave"
            />
        </template>
    </AppMainLayout>
</template>

<script>
import axios from 'axios';
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

export default {
    name: 'ClientShowPage',
    data() {
        return {
            selectedRows: [],
        };
    },
    methods: {
        updateSelectedRows(newSelectedRows) {
            this.selectedRows = newSelectedRows;
        },
        handleAssignAgent(data) {
            const requestIds = this.selectedRows.map(row => row.id);

            console.log('Selected Request IDs:', requestIds);
            axios.post('/requests/assign-agent', {
                agent_ids: data.agent,
                request_ids: requestIds
            })
                .then(response => {
                    console.log('Success:', response.data.message);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Agent successfully assigned to Requests');
                    this.$emit('agent-assigned', response.data.requests);
                })
                .catch(error => {
                    console.error('Error:', error);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Please Select Requests and/or Agents', 'danger');
                });
        }
    }
};
</script>

<script setup="">
import {Head} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";

import {BoxPassportData, BoxListPassportData} from '@boxes/common';
import {BoxCardClientOverview} from '@boxes/client';
import AppMainLayout from "@layouts/AppMainLayout.vue";
import useClientEditComponent from "./composables/useClientEdit.component";
import {CLIENT_TABS_SETTINGS,} from "@pages/clients/constants/tablesAndTabsSettings";
import {BoxClientRequestsAndLinkedContacts} from "@boxes/client";
import {getFullName,} from "@helpers/commonHelpers.js";
import BoxPassportDataEdit from "@boxes/common/BoxPassportDataEdit.vue";
import EntityClientGeneralInfo from "@entities/client/EntityClientGeneralInfo.vue";
import {AlertCancel, AlertDelete} from "@alerts";
import useTableSortable from "@ui/Table/useTableSortable";
import BoxTableRequests from "@boxes/request/BoxTableRequests.vue";
import {FeatureLinkedContactsCreateAndEdit} from "@features";
import PartialPassengersTable from "./partials/PartialPassengersTable.vue";
import PartialLinkedContactsTable from "./partials/PartialLinkedContactsTable.vue";
import {computed} from "vue";
import {useBackendMiddlewarePropsService} from "@services";
import AcmsVBtn from "@ui/Btn.vue";

const props = defineProps({
    agents_to_select: Array,
    resource: Object,
    contacts: Object,
    createdAndUpdated: Object,
    locationTimezone: Object,
    passport: Object,
    passengers: Object,
    linkedContacts: Object,
    requests: Object,
    ffPrograms: Object,
});

const getHeaderBtnClass = 'btn-sm btn-primary ms-auto';

const {
    getClientId,
    clientInfoForm, clientPassportForm,
    locState, tabRequests, tabPassengers,
    tabLinkedContacts, tabLinkedContactsHandlers,
    getPropsForBoxClientOverview,
    clientSaveHandle, onChangeTabHandle,
    passengersHandlers,
    isCreateOrEditPassenger, isCreateOrEditLinkedContact, canShowHeaderAddButton, canShowBottomButtonsForClient,
} = useClientEditComponent({props})

const {backendPermissions} = useBackendMiddlewarePropsService()
console.log('backendPermissions', backendPermissions);
console.log('backendPermissions', backendPermissions.allowAssignAgents)
const {tableForm: requestTableForm, tableSortHandle: requestTableSortHandle} = useTableSortable(
    {
        route: getRoute('clients.getClientCollections', {client: getClientId.value}),
        sendParams: {
            sendType: 'post',
            only: ['requests']
        }
    })

const getTabsItems = computed(() => {
    const items = {
        'client-info': 'Client info',
        'requests': 'Requests',
        'passengers': 'Passengers',
        'linked-contacts': 'Linked contacts',
    }

    if(!backendPermissions?.allowViewPassengers){
        delete items['passengers']
    }
    return items
})

</script>

<style lang="scss">
.client-info-block {
    padding: 20px;
}
</style>
